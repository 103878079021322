.App {
  background: black;
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.full-height {
  height: 100vh;
}

.low-opacity {
  opacity: 0.01;
  width: 100%;
  height: auto;
}

.low-opacity-final {
  opacity: 0.01;
  width: 100vw;
  /* height: 100vh;
  width: auto; */
}

.white {
  color: white;
}


html, body { background-color: black; overflow-x: hidden !important;} 

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.dark {
  background-color: black;
  width: 100%;
  margin: -20;
}

#waterfall{
  animation-name: floating-large;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: absolute;
  left: 0px;
  top: 0px; 
  width: 4vw;
  /* height: 200px; */
}

.intro-text{
  z-index: 100;
  padding-left: 3vw;
  padding-right: 3vw;
}

.dark-background {
  background-color: black;
  height: 100vh;
}
.clouds {
  position: relative;
  top: -3vh;
}



.faq-background {
  /* background: rgb(40,44,52); */
  background: black;
  background: linear-gradient(0deg,black 0%, #4b03af 100%);
}

.roadmap-background {
  /* background: rgb(40,44,52); */
  background: black;
  color: #a974f4;
  background: linear-gradient(0deg,#4b03af 0%, rgba(11,12,13,1) 100%);
}
.info-background {
  /* background: rgb(40,44,52); */
  background: black;
  background: linear-gradient(0deg, black 0%, black 100%);
}

.el {
  height: 16px;
  width: 16px;
  background-color: green;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .jump {
  animation-name: bounce-3;
  animation-timing-function: ease;
} */
@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-60px);
		-ms-transform:     translateY(-60px);
		transform:         translateY(-60px)
	}
	60% {
		-webkit-transform: translateY(-30px);
		-ms-transform:     translateY(-30px);
		transform:         translateY(-30px)
	}
}

.jump {  
  text-align: center;
  -webkit-animation-duration: 2s;
  animation-name: bounce-3;
  cursor: pointer;
}


@keyframes bounce-3 {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-100px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
} 


.init-vertical {
  /* transform: translate(0px, 50vh) */
}

/* @keyframes example {
  0% {
    color: white;
    transform: translate(0px, 50vh);
    font-size: 2rem;
}
100% {
    color: #6cff4e;
    transform: translate(0px, 0vh);
    font-size: 4rem;
}
} */



.date-font {
  font-size: x-large !important;
  color: white !important;
  font-weight: bold !important;
}

.left-align {
  text-align: left !important;
}

.medium {
  font-size: x-large;
}
.timeline-info {
  font-size: large !important;
}

@media only screen and (max-width: 600px) {
  .large {
    font-size: x-large;
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

@media only screen and (min-width: 600px) {
  .large {
    font-size: xx-large;
  }
}


@media only screen and (max-width: 600px) {
  .title {
    font-size: large;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

@media only screen and (min-width: 600px) {
  .title {
    font-size: x-large;
  }
}

@keyframes floating-large{
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 20px); }
  100%   { transform: translate(0, -0px); }   
}

/* @keyframes float-cloud-one {
  0% {
    left: calc(-100%);
  }
  50% { 
    left: calc(100% - 100px); 
   }
  100% {
    left: calc(-100%);     
  }
} */

/* works but not even speed */
/* 0% {margin-left: calc(150%)}
100% {margin-left: calc(-150%);} */

@keyframes moveclouds {
  0% {
    margin-left: -40%;
}
100% {
    margin-left: 80%;
}
	/* 100% {margin-left: calc(-150%);} */
}
/* @keyframes moveclouds {
	0% {margin-left: 1000px;}
	100% {margin-left: -1000px;}
} */
/* @keyframes moveclouds {
	0% {margin-left: 1000px;}
	100% {margin-left: -1000px;}
} */

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

.cloud-one:after {
  opacity: 0.43;
	width: 120px; height: 120px;
	top: -55px; left: auto; right: 15px;
}


@keyframes animateCloudTwo {
  0% {
    left:300%
}
50% {
  /* transform: translate(0, 50px); */
}

100% {
    left:-300%;
}
}

@keyframes animateCloudThree {
  0% {
    left:300%
}
50% {
  /* transform: translate(0, 50px); */
}

100% {
    left:-300%;
}
}

@keyframes animateCloudOne {
  0% {
    left:300%
}
50% {
  /* transform: translate(0, 50px); */
}

100% {
    left:-300%;
    /* transform: translate(0, -0px); */
}
}


@media only screen and (max-width: 600px) {
  .islands {
    position: relative;
    top: 30px;
  }
}
@media only screen and (min-width: 600px) {
  .islands {
    position: relative;
    top: -1vh;
  }
}



.islands-overall {
  position: relative;
  top: -5vh;
}

.island-two {
  position: relative;
}
.clouds {
  position: relative;
  top: 110px;
}
.cloud-two {
  position: relative;
  width: 65%;
  top: -50px;
  -webkit-animation: animateCloudTwo 24s linear infinite;
	-moz-animation: animateCloudTwo 24s linear infinite;
	animation: animateCloudTwo 24s linear infinite;
}

.cloud-three {
  position: relative;
  width: 70%;
  top: -50px;
  -webkit-animation: animateCloudTwo 22s linear infinite;
	-moz-animation: animateCloudTwo 22s linear infinite;
	animation: animateCloudTwo 22s linear infinite;
}

.cloud-one {
  width: 80%;
  position: relative;
	-webkit-animation: animateCloudOne 26s linear infinite;
	-moz-animation: animateCloudOne 26s linear infinite;
	animation: animateCloudOne 26s linear infinite;
	
	/* -moz-animation: moveclouds 15s linear infinite;
	-o-animation: moveclouds 15s linear infinite; */
}

.small-island {
  position: relative;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  width: 40%;
}

.large-island {
  position: relative;
  animation-name: floating-large;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  width: 60%;
}


.rowoverlay{
  position: absolute;
  top: 0;
  left:0;
  z-index:1;
  bottom:0;
  right:0;
  height:100%;
}

.green {
  color: #6cff4e
}

.fa-tiktok {
  color: #111111;
  filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
}

.fa-tiktok:hover {
  color: #111111;
  
  filter: drop-shadow(2px 0px 0px #4DE8F4) drop-shadow(-2px -2px 0px #FD3E3E);

  cursor: pointer;
}

::selection{
  color: #a974f4;
}

.side-container {
  position:relative;
}

@media (max-width: 865px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .side-one {
    z-index: 100;
    position: absolute;
    left: 85vw;
    width: 20vw;
    top: -8vw;
    height: auto;
    transform: rotate(-45deg);
  }
}

@media (min-width: 865px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .side-one {
    z-index: 100;
    position: absolute;
    left: 85vw;
    width: 20vw;
    top: -5vw;
    height: auto;
    transform: rotate(-45deg);
  }
}


.orange {
  /* color: #f49a00 */
  color: #a974f4;
  /* color: #6cff4e; */
}


.myriad {
  font-family: 'Fredoka', sans-serif
}

/* .myriad {
  font-family: Optima,Segoe,Segoe UI,Candara,Calibri,Arial,sans-serif; 
} */

.description-anim {
  /* -webkit-animation: fade-in;
  -webkit-animation-duration: 3s; */
}

/* @keyframes fade-in {
  0%   { opacity: 0 }
  100%  { opacity: 100}
} */

.no-opacity {
  opacity: 0;
}

.bold {
  font-weight: bold;
}

.back-row {
  width: 30%;
  height: auto;
}

.front-row {
  width: 40%;
  position: relative;
}

.front-left {

}

.front-right {

}


.expansion-panel {
  border: #6cff4e !important;
  border-width: 10px !important;
}

.accordion-class {
  border-radius: 10px !important;
}

.container-fluid{
  padding: 0 !important;
}
section.parallax-bg-1{
background-size: cover;
}




.padding-top {
  padding-top: 2.0vh !important;
}
.padding-bottom {
  padding-bottom: 2.0vh !important;
}

.padding-bottom-large {
  padding-bottom: 4.0vh;
}

.faq-title {
  font-weight: bold;
  font-size: x-large;
}

.faq-body{
  font-size: x-large;
  align-items: center !important;
}

.MuiAccordionSummary-content{
  justify-content: center;
}



.fa-instagram {
  color: transparent;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
}

.fa-instagram:hover {
  color: transparent;
  background: radial-gradient(circle at 30% 107%, #285AEB 0%, #285AEB 5%, #d6249f 45%, #fd5949 60%, #fdf497 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #285AEB 0%, #285AEB 5%, #d6249f 45%, #fd5949 60%, #fdf497 90%);
  background-clip: text;
  -webkit-background-clip: text;
  cursor: pointer;
}

.fa-twitter {
  color: #08a0e9;
}

.fa-twitter:hover {
  color: #F5F8FA;
  cursor: pointer;
}

.fa-facebook {
  color: #1778F2;
}

.fa-facebook:hover {
  color: #3b5998;
  cursor: pointer;
}

.fa-tiktok {
  color: #111111;
  filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
}

.fa-discord {
  color: #5865F2
}

.fa-discord:hover {
  color: #9b84ec;
  cursor: pointer;
}

.fa-tiktok:hover {
  color: #111111;
  
  filter: drop-shadow(2px 0px 0px #4DE8F4) drop-shadow(-2px -2px 0px #FD3E3E);

  cursor: pointer;
}

.center-content {
  justify-content: center !important;
}

a {
  text-decoration: none !important;
}

.team-background {
  width: 100%;
  margin-left: 0;
  background: rgb(61,21,115);
background: linear-gradient(0deg, rgba(61,21,115,1) 0%, black 100%);
}

.footer-background {
  background: rgb(30,33,1);
}

.socials-background {
  width: 100%;
  background: rgb(30,33,36);
background: linear-gradient(0deg, rgba(30,33,36,1) 0%, rgba(61,21,115,1) 100%);
}

/* #1e2124 dark gray */
/* #3d1573 cool indigo color */

/* .reduce-margin {
  height: 70%;
} */

.navbar-light .navbar-toggler-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28169, 116, 244, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-nav .nav-link {
  padding-left: 3vw !important;
}

.hos

.no-border {
  border: none !important
}

.padding-left {
  padding-left: 3vw !important;
}

.mild-horizontal-padding {
  padding-left: 2vw;
  padding-right: 2vw;
}
.padding-horizontal {
  padding-left: 5vw;
  padding-right: 5vw;
}
.row {
  --bs-gutter-x: 0rem !important;
}

.svg-scale {
  width: 40%;
  margin: 0;
  padding: 0;
}

.normal-small {
  width: 40%;
  height: auto;
}
.normal {
  width: 70%;
  height: auto;
}

.team-image {
  width: 50%;
  height: auto;
}

.front-center {
  width: 55%;
  position: relative;
  /* top: -400px; */
}

.adjust {
  position: relative;
  /* top: -400px; */
}

.hercules {
  /* font-family: 'Herculanum'; */
  font-family: 'Fredoka', sans-serif;
  font-weight: bold;
}

.no-border {
  border: none !important
}

@media (max-width: 800px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .cozy {
    font-size: 2rem;
  }
}
@media (min-width: 800px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .cozy {
    font-size: 3rem;
  }

}



.vertical-animate {
  /* -webkit-transform: translateZ(0); */
  /* -webkit-transform: translate(, 0);
  -webkit-transition: -webkit-transform 1s linear; */
  /* -webkit-animation: example 1.8s cubic-bezier(0.42, 0.7, 0.8, 1.0) forwards; */
  /* -webkit-transform: example 1.8s linear forwards; */
}

.upward{
  position: relative;
  /* top: -300px; */
}


.hide {
  display: none;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}


.background-test {
  /* background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%); */
  /* clip-path: polygon(100% 0, 100% 57%, 54% 100%, 0 100%, 0 0);
  background-color:rgb(180, 197, 27);
  10% 69% 10% 74% / 53% 20% 10% 10%  */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
